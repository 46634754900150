
import {
  IonPage,
  IonHeader,
  IonContent,
  onIonViewDidEnter,
  onIonViewWillEnter,
} from "@ionic/vue";
import OptionMenu from "@/components/optionMenu.vue";
import Article from "@/components/article.vue";
import { skipRouter } from "@/router";
import { getDicSimpleList } from "@/service/member";
import { onMounted, watch, ref } from "vue";
import { getList } from "@/service/information";

export default {
  name: "InformationList",
  components: { OptionMenu, Article, IonHeader, IonContent, IonPage },
  setup() {
    const data = {
      menus: ref([]) as any,
      checkedMenus: ref("1"),
      articles: ref([]) as any,
      loadStatus: ref(false),
      currentPage: 0,
      pageSize: 10,
    };

    const methods = {
      async getInformations() {
        const { rows, page, total }: any = await getList({
          categoryid: data.checkedMenus.value,
          page: data.currentPage + 1,
          rows: data.pageSize,
        });
        data.articles.value = [...data.articles.value, ...rows];
        data.currentPage = page;
        data.loadStatus.value = page >= total;
      },
      async getNewCategoryDetail() {
        const result: any = await getDicSimpleList({
          enCode: "NewCategory",
        });
        console.log(result);
        data.menus.value = result.map(({ itemname, itemvalue }: any) => ({
          label: itemname,
          value: itemvalue,
        }));
        data.checkedMenus.value = data.menus.value[0].value;
      },
      refresh(event: any) {
        methods.defaultData();
        setTimeout(async () => {
          await methods.getInformations();
          event.target.complete();
        }, 200);
      },
      loadMore(event: any) {
        setTimeout(async () => {
          await methods.getInformations();
          event.target.complete();
        }, 200);
      },
      defaultData() {
        data.articles.value = [];
        data.currentPage = 0;
        data.loadStatus.value = false;
      },
      skip(id: any) {
        skipRouter("/tabs/information/detail", {
          id,
        });
      },
    };

    //进入页面并且渲染完页面后触发，每次进入页面都触发
    onIonViewDidEnter(() => {
      methods.getNewCategoryDetail();
      methods.getInformations();
    });
    //进入页面后触发，每次进入页面都触发
    onIonViewWillEnter(() => {
      console.log("onIonViewWillEnter!");
    });

    watch(data.checkedMenus, () => {
      methods.defaultData();
      methods.getInformations();
    });
    return { ...data, ...methods };
  },
};
