
import { getDateTime } from "@/utils/moment";

export default {
  name: "article",
  props: {
    id: {
      type: [Number, String],
      default: 0,
    },
    img: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    subTitle: {
      type: String,
      default: "",
    },
    dateTime: {
      type: String,
      default: "",
    },
    read: {
      type: String,
      default: "",
    },
  },
  emits: {
    "skip": null,
  },
  setup(props: any, { emit }: any) {
    const handle = () => {
      emit("skip", props.id);
    };

    return { handle, getDateTime };
  },
};
