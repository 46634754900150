import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-64de0368"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "justify-space-between align-center" }
const _hoisted_2 = { class: "title line-overflow" }
const _hoisted_3 = { class: "subtitle line-overflow" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "article-container p-base",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.handle && $setup.handle(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        style: _normalizeStyle({
          width: $props.img.length > 0 ? 'calc(100% - 105px - 12px)' : '100%',
        })
      }, [
        _createElementVNode("h2", _hoisted_2, _toDisplayString($props.title), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString($props.subTitle), 1)
      ], 4),
      ($props.img.length > 0)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "d-block picture",
            src: $props.img,
            alt: ""
          }, null, 8, _hoisted_4))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", {
      class: "footer justify-space-between",
      style: _normalizeStyle({ 'margin-top': $props.img.length > 0 ? '10px' : '20px' })
    }, [
      _createElementVNode("div", null, "发布时间：" + _toDisplayString($setup.getDateTime($props.dateTime)), 1),
      _createElementVNode("div", null, _toDisplayString($props.read) + " 评论", 1)
    ], 4)
  ]))
}