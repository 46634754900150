import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-057ee2a1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "articles" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OptionMenu = _resolveComponent("OptionMenu")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_refresher_content = _resolveComponent("ion-refresher-content")!
  const _component_ion_refresher = _resolveComponent("ion-refresher")!
  const _component_Article = _resolveComponent("Article")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_OptionMenu, {
            options: _ctx.menus,
            modelValue: _ctx.checkedMenus,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checkedMenus) = $event))
          }, null, 8, ["options", "modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_refresher, {
            slot: "fixed",
            onIonRefresh: _ctx.refresh
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_refresher_content)
            ]),
            _: 1
          }, 8, ["onIonRefresh"]),
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.articles, (article) => {
              return (_openBlock(), _createBlock(_component_Article, {
                key: article.newId,
                id: article.newId,
                img: article.newBackImg,
                title: article.newTitle,
                subTitle: article.subTitle,
                dateTime: article.createTime,
                read: article.zdy_NewsFeedBackRefCount,
                onSkip: _ctx.skip
              }, null, 8, ["id", "img", "title", "subTitle", "dateTime", "read", "onSkip"]))
            }), 128))
          ]),
          (!_ctx.loadStatus)
            ? (_openBlock(), _createBlock(_component_ion_infinite_scroll, {
                key: 0,
                onIonInfinite: _cache[1] || (_cache[1] = ($event: any) => (_ctx.loadMore($event)))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_infinite_scroll_content)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}